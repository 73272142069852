import type { SearchableListItem } from 'components/types';
import type {
  AgencyResponseDto,
  DestinationPaginatedRequestDto,
  DestinationPaginatedResponseDto,
  DestinationResponseListDto,
} from 'dtos';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useDestinations({
  query = '',
  page = 0,
  pageSize = 100,
}: DestinationPaginatedRequestDto) {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<DestinationPaginatedResponseDto, Error>(
    `destinations?query=${query}&page=${page}&pageSize=${pageSize}`,
    fetcher,
  );

  return {
    destinations: data?.data || [],
    isLoading,
    error,
  };
}
